import { useRef, useEffect } from 'react';

export function useFirstRender() {
	const firstRender = useRef(true);

	useEffect(() => {
		firstRender.current = false;
	}, []);

	return firstRender.current;
}