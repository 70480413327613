import { ComponentsProps } from "@material-ui/core/styles/props";

const props: ComponentsProps = {
	MuiButtonBase: {
		disableRipple: true
	},
	MuiCardHeader: {
		titleTypographyProps: { variant: "h6" }
	}
};

export default props;