const overrides = {

	MuiCard: {
		root: {
			borderRadius: "6px",
		}
	}

}

export default overrides;